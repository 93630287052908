import { Button as CommonButton, Link as CommonLink, Stack, Text, Box, Toggle, Tooltip } from '@sixfold/common-ui';
import { BackofficeScope } from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  Popup,
  Table as SemanticTable,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';

import { RelationshipSnippet } from './relationship_snippet';
import { CompanyRelationship, RelationshipCompanyIdentifier } from '../../company/entities';
import { getSignupStatusName } from '../../company/utils';
import { ConfirmButton } from '../../components/confirm_button';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  UnifiedCompanyOnboardingStuckReason,
  UnifiedCompanyRelationshipOnboardingStatusUpdateType,
  UpdateCompanyRelationshipOnboardingStatusMutationVariables,
  UpdateCompanyRelationshipSignupStatusMutationVariables,
  UnifiedCompanyRelationshipSignupStatusUpdateType,
  UnifiedCompanyRelationshipSignupStatus,
  UnifiedCompanyRelationshipOnboardingStatus,
} from '../../lib/graphql/result_types';
import { beautifyString } from '../../lib/util/string';
import { Routes } from '../../routes';

export interface RelationshipListDataProps {
  data: {
    companyRelationships: CompanyRelationship[];
    company_id: string;
  };
}

interface RelationshipListProps {
  deleteCompanyRelationship: (companyRelationshipId: string, selectedCompanyId: string) => Promise<void>;
  updateCompanyRelationshipOnboardingStatus: (
    input: UpdateCompanyRelationshipOnboardingStatusMutationVariables['input'],
    selectedCompanyId: string,
  ) => Promise<void>;
  updateCompanyRelationshipSignupStatus: (
    input: UpdateCompanyRelationshipSignupStatusMutationVariables['input'],
    selectedCompanyId: string,
  ) => Promise<void>;
}

class RelationshipTable extends Table<CompanyRelationship> {}

export const RelationshipList: React.FC<RelationshipListProps & RelationshipListDataProps> = ({
  data,
  deleteCompanyRelationship,
  updateCompanyRelationshipOnboardingStatus,
  updateCompanyRelationshipSignupStatus,
}) => {
  const { companyRelationships, company_id } = data;

  const [isLoading, setIsLoading] = React.useState(false);
  const [showSignupStatus, setShowSignupStatus] = React.useState(false);
  const isAdmin = useIsAdmin();
  const hasCompanyRelationshipPermission = useHasScopes([BackofficeScope.companyWriteNetworkRelations]);
  const isPrivilegedUser = isAdmin || hasCompanyRelationshipPermission;

  const updateRelationshipOnboardingStatus = React.useCallback(
    async (input: UpdateCompanyRelationshipOnboardingStatusMutationVariables['input']) => {
      const companyId = company_id;

      if (isNil(companyId)) {
        throw new Error('No company to update');
      }

      setIsLoading(true);

      try {
        await updateCompanyRelationshipOnboardingStatus(input, companyId);
      } finally {
        setIsLoading(false);
      }
    },
    [company_id, updateCompanyRelationshipOnboardingStatus],
  );

  const updateRelationshipSignupStatus = React.useCallback(
    async (input: UpdateCompanyRelationshipSignupStatusMutationVariables['input']) => {
      const companyId = company_id;

      if (isNil(companyId)) {
        throw new Error('No company to update');
      }

      setIsLoading(true);

      try {
        await updateCompanyRelationshipSignupStatus(input, companyId);
      } finally {
        setIsLoading(false);
      }
    },
    [company_id, updateCompanyRelationshipSignupStatus],
  );

  const renderTourStatusScopeSwitchRenderer = (tourStatusScope: string) => {
    switch (tourStatusScope) {
      case 'ACCEPTED':
        return <div className="ui green label">{beautifyString(tourStatusScope)}</div>;
      case 'DECLINED':
        return <div className="ui red label">{beautifyString(tourStatusScope)}</div>;
      case 'REQUESTED':
        return <div className="ui yellow label">{beautifyString(tourStatusScope)}</div>;
      default:
        return '';
    }
  };

  const getStatusName = (
    unifiedSignupStatus: UnifiedCompanyRelationshipSignupStatus | null,
    unifiedOnboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null,
  ) => {
    const status = showSignupStatus ? unifiedSignupStatus : unifiedOnboardingStatus;
    if (isNil(status)) {
      return '-';
    }
    if (showSignupStatus) {
      return getSignupStatusName(status as UnifiedCompanyRelationshipSignupStatus);
    }
    return beautifyString(status);
  };

  return (
    <div>
      <Stack justifyContent="space-between" alignItems="center">
        <h2 className="table__header">
          <span>Company Relationships</span>
          {isPrivilegedUser && (
            <NavLink
              to={Routes.CompanyRelationshipCreate.generatePath({ company_id })}
              className="ui button primary tiny">
              Add Relationship
            </NavLink>
          )}
        </h2>
        <Toggle
          label={
            <Stack alignItems="center">
              <Text>Signup status</Text>
              <Tooltip content="Signup status is a replacement for onboarding status that is currently in development">
                <CommonButton size="xsmall" kind="ghost" iconStart="small-help" hideLabel>
                  Help
                </CommonButton>
              </Tooltip>
            </Stack>
          }
          onChange={() => setShowSignupStatus(!showSignupStatus)}
        />
      </Stack>
      <RelationshipTable
        className="ui very basic sortable table"
        data={companyRelationships ?? []}
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: [
            { keyPath: 'companyRelationshipId', value: '#' },
            { keyPath: 'sourceCompany.company_name', value: 'Source Company' },
            { keyPath: 'targetCompany.company_name', value: 'Target Company' },
            { keyPath: 'relationshipType', value: 'Relationship' },
            { value: 'Identifiers' },
            { value: 'Data sharing consent' },
            { value: showSignupStatus ? 'Signup status' : 'Onboarding status' },
            { value: showSignupStatus ? 'Update signup status' : 'Update onboarding status' },
            { value: 'Actions' },
          ],
        }}
        defaultSortBy={{ keyPath: 'companyRelationshipId', value: 'ASC' }}
        emptyStatePlaceholder={emptyTableState('No relationships')}>
        {({ row }) => {
          const {
            companyRelationshipId,
            sourceCompany,
            targetCompany,
            relationshipType,
            relationIdentifiers,
            unifiedOnboardingStatus,
            unifiedOnboardingStuckReason,
            unifiedOnboardingEvents,
            dataSharingConsent,
            unifiedSignupStatus,
            unifiedSignupEvents,
          } = row.data;

          const events = showSignupStatus ? unifiedSignupEvents : unifiedOnboardingEvents;
          const stuckReason = showSignupStatus ? null : unifiedOnboardingStuckReason;

          return (
            <tr key={companyRelationshipId}>
              <td className="right aligned">{companyRelationshipId}</td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id: sourceCompany.company_id })} className="item">
                  {sourceCompany.company_name}
                </NavLink>
              </td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id: targetCompany.company_id })} className="item">
                  {targetCompany.company_name}
                </NavLink>
              </td>
              <td>
                <RelationshipSnippet
                  sourceCompany={sourceCompany}
                  targetCompany={targetCompany}
                  relationshipType={relationshipType}
                />
              </td>
              <td>
                <RelationIdentifierLabels identifiers={relationIdentifiers} />
              </td>
              <td>
                <Popup
                  hoverable
                  wide="very"
                  trigger={
                    <span>
                      {notNil(dataSharingConsent)
                        ? renderTourStatusScopeSwitchRenderer(dataSharingConsent.tourStatusScope)
                        : '-'}{' '}
                      <Icon name="info circle" />
                    </span>
                  }>
                  {(dataSharingConsent?.history ?? []).length === 0 ? (
                    <span>No consent entry exists. This is considered equivalent to a declined consent.</span>
                  ) : (
                    <SemanticTable basic="very" padded size="large">
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>Time</TableHeaderCell>
                          <TableHeaderCell>Consent source</TableHeaderCell>
                          <TableHeaderCell>Tour status scope</TableHeaderCell>
                          <TableHeaderCell>Triggered by</TableHeaderCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {dataSharingConsent?.history?.map(
                          ({ updatedAt, consentSource, tourStatusScope, updatedByUser }, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                <FormattedDate date={updatedAt} />
                              </TableCell>
                              <TableCell>{beautifyString(consentSource)}</TableCell>
                              <TableCell>{renderTourStatusScopeSwitchRenderer(tourStatusScope)}</TableCell>
                              <TableCell>
                                {updatedByUser !== null ? (
                                  <Link to={Routes.User.generatePath({ user_id: updatedByUser.user_id })}>
                                    {updatedByUser.first_name} {updatedByUser.last_name}
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </SemanticTable>
                  )}
                </Popup>
              </td>
              <td>
                <Popup
                  hoverable
                  wide="very"
                  trigger={
                    <span>
                      {getStatusName(unifiedSignupStatus, unifiedOnboardingStatus)}
                      {notNil(stuckReason) ? ` (${stuckReason})` : ''} <Icon name="info circle" />
                    </span>
                  }>
                  {(events ?? []).length === 0 ? (
                    <span>No events</span>
                  ) : (
                    <SemanticTable basic="very" padded size="large">
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>Time</TableHeaderCell>
                          <TableHeaderCell>Event</TableHeaderCell>
                          <TableHeaderCell>Triggered by</TableHeaderCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {events?.map(({ eventTime, type, triggeredByUser }, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <FormattedDate date={eventTime} />
                            </TableCell>
                            <TableCell>{beautifyString(type)}</TableCell>
                            <TableCell>
                              {triggeredByUser !== null ? (
                                <Link to={Routes.User.generatePath({ user_id: triggeredByUser.user_id })}>
                                  {triggeredByUser.first_name} {triggeredByUser.last_name}
                                </Link>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </SemanticTable>
                  )}
                </Popup>
              </td>
              <td>
                {showSignupStatus ? (
                  <Dropdown
                    disabled={isLoading}
                    icon={null}
                    pointing="top right"
                    trigger={<Button size="mini">Update signup status</Button>}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={async () =>
                          await updateRelationshipSignupStatus({
                            companyRelationshipId,
                            update: UnifiedCompanyRelationshipSignupStatusUpdateType.RESET_TO_AUTOMATIC,
                          })
                        }>
                        Reset to calculated status
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={async () =>
                          await updateRelationshipSignupStatus({
                            companyRelationshipId,
                            update: UnifiedCompanyRelationshipSignupStatusUpdateType.OUT_OF_SCOPE,
                          })
                        }>
                        Out of scope
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown
                    disabled={isLoading}
                    icon={null}
                    pointing="top right"
                    trigger={<Button size="mini">Update onboarding status</Button>}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={async () =>
                          await updateRelationshipOnboardingStatus({
                            companyRelationshipId,
                            update: UnifiedCompanyRelationshipOnboardingStatusUpdateType.RESET_TO_AUTOMATIC,
                          })
                        }>
                        Reset to calculated status
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={async () =>
                          await updateRelationshipOnboardingStatus({
                            companyRelationshipId,
                            update: UnifiedCompanyRelationshipOnboardingStatusUpdateType.OUT_OF_SCOPE,
                          })
                        }>
                        Out of scope
                      </Dropdown.Item>
                      <Dropdown.Header>Onboarding stuck</Dropdown.Header>
                      <Dropdown.Item
                        onClick={async () =>
                          await updateRelationshipOnboardingStatus({
                            companyRelationshipId,
                            update: UnifiedCompanyRelationshipOnboardingStatusUpdateType.ONBOARDING_STUCK,
                            stuckReason: UnifiedCompanyOnboardingStuckReason.NO_VISIBILITY_PROVIDED,
                          })
                        }>
                        No visibility provided
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
              <td>
                <ConfirmButton
                  label="Delete this relationship"
                  disabled={!isPrivilegedUser}
                  onConfirm={async () => await deleteCompanyRelationship(companyRelationshipId, company_id)}
                />
              </td>
            </tr>
          );
        }}
      </RelationshipTable>
    </div>
  );
};

const RelationIdentifierLabels = ({ identifiers }: { identifiers: RelationshipCompanyIdentifier[] }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasMoreThan2 = identifiers.length > 2;
  const visibleIdentifiers = isExpanded ? identifiers : identifiers.slice(0, 2);

  if (identifiers.length === 0) {
    return <>-</>;
  }

  return (
    <>
      {visibleIdentifiers.map(({ identifierValue, companyIdentifierId, source }) => (
        <Box
          padding="xsmall"
          radius="small"
          className="company__relationships-identifierContainer"
          key={companyIdentifierId}>
          <Stack justifyContent="space-between" alignItems="center">
            <Text weight="bold" size="small">
              {identifierValue}
            </Text>
            <Text style={{ fontSize: '7px' }}>{source}</Text>
          </Stack>
        </Box>
      ))}
      {hasMoreThan2 && (
        <>
          <CommonLink as="button" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Show less' : 'Show more'}
          </CommonLink>
        </>
      )}
    </>
  );
};
