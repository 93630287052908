import { Loader } from '@sixfold/loader-container';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { NavLink, Route, Redirect, Switch } from 'react-router-dom';
import { Button, Dropdown } from 'semantic-ui-react';

import {
  TelematicsProvidersListQuery,
  TelematicsProvidersListQueryVariables,
  TelematicsIntegrationsListQuery,
  getNodes,
} from '../../lib/graphql';
import { Routes } from '../../routes';
import { IntegrationList } from '../components/integration_list';
import { ProvidersList, Props as ProvidersListProps } from '../components/providers_list';
import { TelematicIntegration } from '../entities';
import { telematicsProvidersListQuery, telematicsIntegrationsListQuery } from '../graphql';

export interface ListProps {
  data: {
    integrations: TelematicIntegration[] | null;
  };
}

class ProvidersListLoadingContainer extends Loader<
  TelematicsProvidersListQuery,
  { data: ProvidersListProps },
  object
> {}
class ProvidersListContainerQuery extends Query<TelematicsProvidersListQuery, TelematicsProvidersListQueryVariables> {}

class IntegrationsListLoadingContainer extends Loader<TelematicsIntegrationsListQuery, ListProps, object> {}
class IntegrationsListContainerQuery extends Query<TelematicsIntegrationsListQuery> {}

interface Props {
  onClearFilters: () => void;
  onFilterToggle: (field: { name: string; value: string | undefined }) => void;
  activeFilters: {
    is_implemented?: boolean;
    has_instructions_url?: boolean;
    is_internal_use_only?: boolean;
    is_external_configuration_needed?: boolean;
    is_vehicle_management_enabled?: boolean;
    is_standard_telematics_push_provider?: boolean;
    is_global_allocation_forbidden?: boolean;
    is_deprecated?: boolean;
  };
}
export const TelematicsProvidersContainer = ({ activeFilters, onFilterToggle, onClearFilters }: Props) => {
  return (
    <React.Fragment>
      <h2>Telematics</h2>
      <div className="ui pointing menu submenu">
        <NavLink to={Routes.TelematicsProviders.generatePath({})} className="item">
          Providers
        </NavLink>
        <NavLink to={Routes.TelematicIntegrations.generatePath({})} className="item">
          Integrations
        </NavLink>
      </div>
      <Switch>
        <Route
          path={Routes.TelematicsProviders.routerPath}
          render={() => (
            <ProvidersListContainerQuery
              query={telematicsProvidersListQuery}
              variables={{
                isIntegrationImplemented: activeFilters.is_implemented,
                hasIntegrationInstructionsURL: activeFilters.has_instructions_url,
                isInternalUseOnly: activeFilters.is_internal_use_only,
                isExternalConfigurationNeeded: activeFilters.is_external_configuration_needed,
                isVehicleManagementEnabled: activeFilters.is_vehicle_management_enabled,
                isStandardTelematicsPushProvider: activeFilters.is_standard_telematics_push_provider,
                isGlobalAllocationForbidden: activeFilters.is_global_allocation_forbidden,
                isDeprecated: activeFilters.is_deprecated,
              }}>
              {(result) => (
                <ProvidersListLoadingContainer
                  result={result}
                  mapData={({ data }) => ({
                    data: {
                      telematicsProviders: getNodes(data.telematicsProviders).map((provider) => ({
                        id: provider.telematicsProviderId,
                        name: provider.telematicsProviderName,
                        isInternalUseOnly: provider.isInternalUseOnly,
                        integrationInstructionsURL: provider.integrationInstructionsURL,
                        isIntegrationImplemented: provider.isIntegrationImplemented,
                        isExternalConfigurationNeeded: provider.isExternalConfigurationNeeded,
                        isVehicleManagementEnabled: provider.isVehicleManagementEnabled,
                        isStandardTelematicsPushProvider: provider.isStandardTelematicsPushProvider,
                        isGlobalAllocationForbidden: provider.isGlobalAllocationForbidden,
                        isDeprecated: provider.isDeprecated,
                        numIntegrations: provider.telematicsIntegrations.count,
                        numActiveIntegrations: provider.enabledIntegrations.count,
                        numTotalTrucks: provider.totalTrucksCount,
                        numActiveTrucks: provider.activeTrucksCount,
                      })),
                    },
                  })}>
                  {({ data }) => (
                    <React.Fragment>
                      <h2 className="table__header">
                        Telematics providers
                        <Dropdown
                          placeholder="Implementation status"
                          selection
                          compact
                          options={[
                            { text: 'Implementation status', value: '' },
                            { text: 'Integration implemented', value: 'true' },
                            { text: 'Integration not implemented', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_implemented === undefined ? '' : activeFilters.is_implemented.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_implemented',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Instructions URL"
                          selection
                          compact
                          options={[
                            { text: 'Instructions URL', value: '' },
                            { text: 'Has instructions URL', value: 'true' },
                            { text: "Doesn't have instructions URL", value: 'false' },
                          ]}
                          value={
                            activeFilters.has_instructions_url === undefined
                              ? ''
                              : activeFilters.has_instructions_url.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'has_instructions_url',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Accessibility"
                          selection
                          compact
                          options={[
                            { text: 'Accessibility', value: '' },
                            { text: 'Internally accessible only', value: 'true' },
                            { text: 'Publicly accessible', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_internal_use_only === undefined
                              ? ''
                              : activeFilters.is_internal_use_only.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_internal_use_only',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="External configuration"
                          selection
                          compact
                          options={[
                            { text: 'External configuration', value: '' },
                            { text: 'External configuration needed', value: 'true' },
                            { text: 'External configuration not needed', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_external_configuration_needed === undefined
                              ? ''
                              : activeFilters.is_external_configuration_needed.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_external_configuration_needed',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Vehicle management"
                          selection
                          compact
                          options={[
                            { text: 'Vehicle management', value: '' },
                            { text: 'Vehicle management enabled', value: 'true' },
                            { text: 'Vehicle management not enabled', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_vehicle_management_enabled === undefined
                              ? ''
                              : activeFilters.is_vehicle_management_enabled.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_vehicle_management_enabled',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Standard telematics push provider"
                          selection
                          compact
                          options={[
                            { text: 'Standard telematics push provider', value: '' },
                            { text: 'Is standard telematics push provider', value: 'true' },
                            { text: 'Is not standard telematics push provider', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_standard_telematics_push_provider === undefined
                              ? ''
                              : activeFilters.is_standard_telematics_push_provider.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_standard_telematics_push_provider',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Global allocation"
                          selection
                          compact
                          options={[
                            { text: 'Global allocation', value: '' },
                            { text: 'Global allocation forbidden', value: 'true' },
                            { text: 'Global allocation allowed', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_global_allocation_forbidden === undefined
                              ? ''
                              : activeFilters.is_global_allocation_forbidden.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_global_allocation_forbidden',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        <Dropdown
                          placeholder="Deprecated"
                          selection
                          compact
                          options={[
                            { text: 'Deprecated', value: '' },
                            { text: 'Is deprecated', value: 'true' },
                            { text: 'Is not deprecated', value: 'false' },
                          ]}
                          value={
                            activeFilters.is_deprecated === undefined ? '' : activeFilters.is_deprecated.toString()
                          }
                          onChange={(_e, { value }) => {
                            onFilterToggle({
                              name: 'is_deprecated',
                              value: value === '' ? undefined : value?.toString(),
                            });
                          }}
                        />
                        {(activeFilters.has_instructions_url !== undefined ||
                          activeFilters.is_implemented !== undefined ||
                          activeFilters.is_internal_use_only !== undefined ||
                          activeFilters.is_deprecated !== undefined ||
                          activeFilters.is_external_configuration_needed !== undefined ||
                          activeFilters.is_standard_telematics_push_provider !== undefined ||
                          activeFilters.is_global_allocation_forbidden !== undefined ||
                          activeFilters.is_vehicle_management_enabled !== undefined) && (
                          <Button onClick={onClearFilters} size="tiny">
                            Clear filters
                          </Button>
                        )}
                      </h2>
                      Showing {data.telematicsProviders.length} providers
                      <ProvidersList telematicsProviders={data.telematicsProviders} />
                    </React.Fragment>
                  )}
                </ProvidersListLoadingContainer>
              )}
            </ProvidersListContainerQuery>
          )}
        />
        <Route
          path={Routes.TelematicIntegrations.routerPath}
          render={() => (
            <IntegrationsListContainerQuery query={telematicsIntegrationsListQuery}>
              {(result) => (
                <IntegrationsListLoadingContainer
                  result={result}
                  mapData={({ data }) => ({
                    data: {
                      integrations: notNil(data.telematicsIntegrations)
                        ? getNodes(data.telematicsIntegrations.integrations)
                        : [],
                      company_id: '1',
                    },
                  })}>
                  {({ data }) => (
                    <React.Fragment>
                      <h2 className="table__header">Integrations</h2>
                      <IntegrationList hideCredentials integrations={data.integrations} />
                    </React.Fragment>
                  )}
                </IntegrationsListLoadingContainer>
              )}
            </IntegrationsListContainerQuery>
          )}
        />
        <Redirect to={Routes.TelematicsProviders.generatePath({})} />
      </Switch>
    </React.Fragment>
  );
};
