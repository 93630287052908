import { generatePath as routerGeneratePath } from 'react-router-dom';

interface Route<T extends Record<string, string | undefined>> {
  /* Path that is safe to give to react-router as path */
  routerPath: string;

  /* Function for building a path based on parameters */
  generatePath: (params: T) => string;
}

export type Home = Record<string, never>;
export const Home: Route<Home> = {
  routerPath: '/',
  generatePath: (params) => routerGeneratePath(Home.routerPath, params),
};

export type KPI = Record<string, never>;
export const KPI: Route<KPI> = {
  routerPath: '/kpi',
  generatePath: (params) => routerGeneratePath(KPI.routerPath, params),
};

export type Vehicles = Record<string, never>;
export const Vehicles: Route<Vehicles> = {
  routerPath: '/vehicles',
  generatePath: (params) => routerGeneratePath(Vehicles.routerPath, params),
};

export type Vehicle = { vehicle_id: string };
export const Vehicle: Route<Vehicle> = {
  routerPath: '/vehicles/:vehicle_id',
  generatePath: (params) => routerGeneratePath(Vehicle.routerPath, params),
};

export type Tours = Record<string, never>;
export const Tours: Route<Tours> = {
  routerPath: '/tours',
  generatePath: (params) => routerGeneratePath(Tours.routerPath, params),
};

export type Tour = { tour_id: string };
export const Tour: Route<Tour> = {
  routerPath: '/tours/:tour_id',
  generatePath: (params) => routerGeneratePath(Tour.routerPath, params),
};

export type TourStops = { tour_id: string };
export const TourStops: Route<TourStops> = {
  routerPath: '/tours/:tour_id/stops',
  generatePath: (params) => routerGeneratePath(TourStops.routerPath, params),
};

export type TourDeliveries = { tour_id: string };
export const TourDeliveries: Route<TourDeliveries> = {
  routerPath: '/tours/:tour_id/deliveries',
  generatePath: (params) => routerGeneratePath(TourDeliveries.routerPath, params),
};

export type TourVisibility = { tour_id: string };
export const TourVisibility: Route<TourVisibility> = {
  routerPath: '/tours/:tour_id/visibility',
  generatePath: (params) => routerGeneratePath(TourVisibility.routerPath, params),
};

export type TourEvents = { tour_id: string };
export const TourEvents: Route<TourEvents> = {
  routerPath: '/tours/:tour_id/events',
  generatePath: (params) => routerGeneratePath(TourEvents.routerPath, params),
};

export type TourCarbon = { tour_id: string };
export const TourCarbon: Route<TourCarbon> = {
  routerPath: '/tours/:tour_id/carbon',
  generatePath: (params) => routerGeneratePath(TourCarbon.routerPath, params),
};

export type TourUnified = { tour_id: string };
export const TourUnified: Route<TourUnified> = {
  routerPath: '/tours/:tour_id/unified',
  generatePath: (params) => routerGeneratePath(TourUnified.routerPath, params),
};

export type TourKafka = { tour_id: string };
export const TourKafka: Route<TourKafka> = {
  routerPath: '/tours/:tour_id/kafka',
  generatePath: (params) => routerGeneratePath(TourKafka.routerPath, params),
};

export type TourTraffic = { tour_id: string };
export const TourTraffic: Route<TourTraffic> = {
  routerPath: '/tours/:tour_id/traffic',
  generatePath: (params) => routerGeneratePath(TourTraffic.routerPath, params),
};

export type TourRelatedTransports = { tour_id: string };
export const TourRelatedTransports: Route<TourRelatedTransports> = {
  routerPath: '/tours/:tour_id/related_transports',
  generatePath: (params) => routerGeneratePath(TourRelatedTransports.routerPath, params),
};

export type Companies = Record<string, never>;
export const Companies: Route<Companies> = {
  routerPath: '/companies',
  generatePath: (params) => routerGeneratePath(Companies.routerPath, params),
};

export type CompanySupportedRoutes = { CompanySupportedRoutes_id: string };
export const CompanySupportedRoutes: Route<CompanySupportedRoutes> = {
  routerPath:
    '/companies/:company_id/(users|integrations|platforms|carriers|shippers|relationships|grants|assets|onboarding|groups|configuration|features|dashboards|kafka)',
  generatePath: (params) => routerGeneratePath(CompanySupportedRoutes.routerPath, params),
};

export type Company = { company_id: string };
export const Company: Route<Company> = {
  routerPath: '/companies/:company_id',
  generatePath: (params) => routerGeneratePath(Company.routerPath, params),
};

export type CompanyCreate = Record<string, never>;
export const CompanyCreate: Route<CompanyCreate> = {
  routerPath: '/companies/new',
  generatePath: (params) => routerGeneratePath(CompanyCreate.routerPath, params),
};

export type CompanyEdit = { company_id: string };
export const CompanyEdit: Route<Company> = {
  routerPath: '/companies/:company_id/edit',
  generatePath: (params) => routerGeneratePath(CompanyEdit.routerPath, params),
};

export type CompanyUsers = { company_id: string };
export const CompanyUsers: Route<CompanyUsers> = {
  routerPath: '/companies/:company_id/users',
  generatePath: (params) => routerGeneratePath(CompanyUsers.routerPath, params),
};

export type CompanyUser = { company_id: string; user_id: string };
export const CompanyUser: Route<CompanyUser> = {
  routerPath: '/companies/:company_id/users/:user_id',
  generatePath: (params) => routerGeneratePath(CompanyUser.routerPath, params),
};

export type CompanyUserCreate = { company_id: string };
export const CompanyUserCreate: Route<CompanyUserCreate> = {
  routerPath: '/companies/:company_id/users/new',
  generatePath: (params) => routerGeneratePath(CompanyUserCreate.routerPath, params),
};

export type CompanyUserGroups = { company_id: string };
export const CompanyUserGroups: Route<CompanyUserGroups> = {
  routerPath: '/companies/:company_id/groups',
  generatePath: (params) => routerGeneratePath(CompanyUserGroups.routerPath, params),
};

export type CompanyUserGroup = { company_id: string; group_id: string };
export const CompanyUserGroup: Route<CompanyUserGroup> = {
  routerPath: '/companies/:company_id/groups/:group_id',
  generatePath: (params) => routerGeneratePath(CompanyUserGroup.routerPath, params),
};

export type CompanyTelematicsIntegrations = { company_id: string };
export const CompanyTelematicsIntegrations: Route<CompanyTelematicsIntegrations> = {
  routerPath: '/companies/:company_id/integrations',
  generatePath: (params) => routerGeneratePath(CompanyTelematicsIntegrations.routerPath, params),
};

export type CompanyTelematicsIntegration = { company_id: string; telematics_integration_id: string };
export const CompanyTelematicsIntegration: Route<CompanyTelematicsIntegration> = {
  routerPath: '/companies/:company_id/integrations/:telematics_integration_id',
  generatePath: (params) => routerGeneratePath(CompanyTelematicsIntegration.routerPath, params),
};

export type CompanyTelematicsIntegrationCreate = { company_id: string };
export const CompanyTelematicsIntegrationCreate: Route<CompanyTelematicsIntegrationCreate> = {
  routerPath: '/companies/:company_id/integrations/new',
  generatePath: (params) => routerGeneratePath(CompanyTelematicsIntegrationCreate.routerPath, params),
};

export type CompanyPlatforms = { company_id: string };
export const CompanyPlatforms: Route<CompanyPlatforms> = {
  routerPath: '/companies/:company_id/platforms',
  generatePath: (params) => routerGeneratePath(CompanyPlatforms.routerPath, params),
};

export type CompanyPlatform = { company_id: string; platform_id: string };
export const CompanyPlatform: Route<CompanyPlatform> = {
  routerPath: '/companies/:company_id/platforms/:platform_id',
  generatePath: (params) => routerGeneratePath(CompanyPlatform.routerPath, params),
};

export type CompanyPlatformCreate = { company_id: string };
export const CompanyPlatformCreate: Route<CompanyPlatformCreate> = {
  routerPath: '/companies/:company_id/platforms/new',
  generatePath: (params) => routerGeneratePath(CompanyPlatformCreate.routerPath, params),
};

export type CompanyRelationships = { company_id: string };
export const CompanyRelationships: Route<CompanyRelationships> = {
  routerPath: '/companies/:company_id/relationships',
  generatePath: (params) => routerGeneratePath(CompanyRelationships.routerPath, params),
};

export type CompanyRelationship = { company_id: string; company_relationship_id: string };
export const CompanyRelationship: Route<CompanyRelationship> = {
  routerPath: '/companies/:company_id/relationships/:company_relationship_id',
  generatePath: (params) => routerGeneratePath(CompanyRelationship.routerPath, params),
};

export type CompanyRelationshipCreate = { company_id: string };
export const CompanyRelationshipCreate: Route<CompanyRelationshipCreate> = {
  routerPath: '/companies/:company_id/relationships/new',
  generatePath: (params) => routerGeneratePath(CompanyRelationshipCreate.routerPath, params),
};

export type CompanyAssets = { company_id: string };
export const CompanyAssets: Route<CompanyAssets> = {
  routerPath: '/companies/:company_id/assets',
  generatePath: (params) => routerGeneratePath(CompanyAssets.routerPath, params),
};

export type CompanyOnboarding = { company_id: string };
export const CompanyOnboarding: Route<CompanyOnboarding> = {
  routerPath: '/companies/:company_id/onboarding',
  generatePath: (params) => routerGeneratePath(CompanyOnboarding.routerPath, params),
};

export type CompanyVehicleGrants = { company_id: string };
export const CompanyVehicleGrants: Route<CompanyVehicleGrants> = {
  routerPath: '/companies/:company_id/grants',
  generatePath: (params) => routerGeneratePath(CompanyVehicleGrants.routerPath, params),
};

export type CompanyShipperPartners = { company_id: string };
export const CompanyShipperPartners: Route<CompanyShipperPartners> = {
  routerPath: '/companies/:company_id/shippers',
  generatePath: (params) => routerGeneratePath(CompanyShipperPartners.routerPath, params),
};

export type CompanyCarrierPartners = { company_id: string };
export const CompanyCarrierPartners: Route<CompanyCarrierPartners> = {
  routerPath: '/companies/:company_id/carriers',
  generatePath: (params) => routerGeneratePath(CompanyCarrierPartners.routerPath, params),
};

export type CompanyConfiguration = { company_id: string };
export const CompanyConfiguration: Route<CompanyConfiguration> = {
  routerPath: '/companies/:company_id/configuration',
  generatePath: (params) => routerGeneratePath(CompanyConfiguration.routerPath, params),
};

export type CompanyFeatureFlags = { company_id: string };
export const CompanyFeatureFlags: Route<CompanyFeatureFlags> = {
  routerPath: '/companies/:company_id/features',
  generatePath: (params) => routerGeneratePath(CompanyFeatureFlags.routerPath, params),
};

export type CompanyDashboards = { company_id: string };
export const CompanyDashboards: Route<CompanyDashboards> = {
  routerPath: '/companies/:company_id/dashboards',
  generatePath: (params) => routerGeneratePath(CompanyDashboards.routerPath, params),
};

export type CompanyKafka = { company_id: string };
export const CompanyKafka: Route<CompanyKafka> = {
  routerPath: '/companies/:company_id/kafka',
  generatePath: (params) => routerGeneratePath(CompanyKafka.routerPath, params),
};

export type Users = Record<string, string>;
export const Users: Route<Users> = {
  routerPath: '/users/',
  generatePath: (params) => routerGeneratePath(Users.routerPath, params),
};

export type User = { user_id: string };
export const User: Route<User> = {
  routerPath: '/users/:user_id',
  generatePath: (params) => routerGeneratePath(User.routerPath, params),
};

export type Telematics = Record<string, string>;
export const Telematics: Route<Telematics> = {
  routerPath: '/telematics',
  generatePath: (params) => routerGeneratePath(Telematics.routerPath, params),
};

export type TelematicsProviders = Record<string, string>;
export const TelematicsProviders: Route<TelematicsProviders> = {
  routerPath: '/telematics/providers',
  generatePath: (params) => routerGeneratePath(TelematicsProviders.routerPath, params),
};

export type TelematicsProvider = { provider_id: string };
export const TelematicsProvider: Route<TelematicsProvider> = {
  routerPath: '/telematics/providers/:provider_id',
  generatePath: (params) => routerGeneratePath(TelematicsProvider.routerPath, params),
};

export type TelematicIntegrations = Record<string, never>;
export const TelematicIntegrations: Route<TelematicIntegrations> = {
  routerPath: '/telematics/integrations',
  generatePath: (params) => routerGeneratePath(TelematicIntegrations.routerPath, params),
};
export type Miscellaneous = Record<string, never>;
export const Miscellaneous: Route<Miscellaneous> = {
  routerPath: '/miscellaneous',
  generatePath: (params) => routerGeneratePath(Miscellaneous.routerPath, params),
};

export type ApiUsage = Record<string, never>;
export const ApiUsage: Route<ApiUsage> = {
  routerPath: '/visibility_api',
  generatePath: (params) => routerGeneratePath(ApiUsage.routerPath, params),
};

export type Login = Record<string, never>;
export const Login: Route<Login> = {
  routerPath: '/login',
  generatePath: (params) => routerGeneratePath(Login.routerPath, params),
};

export type Logout = Record<string, never>;
export const Logout: Route<Logout> = {
  routerPath: '/logout',
  generatePath: (params) => routerGeneratePath(Logout.routerPath, params),
};
