import { Loader } from '@sixfold/loader-container';
import { BackofficeScope } from '@sixfold/session-interface';
import React from 'react';
import { Query } from 'react-apollo';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  ApiEndpointUsageLog,
  CompanyIntegrationsListQuery,
  CompanyIntegrationsListQueryVariables,
  notEmpty,
} from '../../lib/graphql';
import { Routes } from '../../routes';
import { ApiEndpointUsageLogsList } from '../../telematic_integration/components/api_endpoint_usage_log_list';
import { IntegrationList } from '../../telematic_integration/components/integration_list';
import { DeleteIntegrationMutation } from '../../telematic_integration/containers/delete_integration_container';
import { TelematicIntegration } from '../../telematic_integration/entities';
import { companyIntegrationsListQuery } from '../graphql';

export interface ListProps {
  data: {
    integrations: TelematicIntegration[] | null;
    api_endpoint_usage_logs_data: {
      api_endpoint_usage_logs: ApiEndpointUsageLog[];
      shipper_tours_count: number;
      carrier_tours_count: number;
    };
    company_name: string | null;
  };
}

class LoadingContainer extends Loader<CompanyIntegrationsListQuery, ListProps, CompanyIntegrationsListQueryVariables> {}
class IntegrationListContainerQuery extends Query<
  CompanyIntegrationsListQuery,
  CompanyIntegrationsListQueryVariables
> {}

export const CompanyIntegrationsContainer: React.FunctionComponent<RouteComponentProps<{ company_id: string }>> = ({
  match: {
    params: { company_id },
  },
}) => {
  const hasIntegrationsEditPermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);

  return (
    <React.Fragment>
      <h2>
        Integrations{' '}
        {(useIsAdmin() || hasIntegrationsEditPermission) && (
          <NavLink
            to={Routes.CompanyTelematicsIntegrationCreate.generatePath({ company_id })}
            className="ui button primary tiny">
            Add Integration
          </NavLink>
        )}
      </h2>

      <IntegrationListContainerQuery query={companyIntegrationsListQuery} variables={{ company_id }}>
        {(result) => (
          <DeleteIntegrationMutation companyId={company_id}>
            {({ deleteTelematicsIntegration }) => (
              <LoadingContainer
                result={result}
                mapData={({ data }) => ({
                  data: {
                    integrations: (data.company?.telematics_integrations ?? []).filter(notEmpty),
                    api_endpoint_usage_logs_data: {
                      api_endpoint_usage_logs: (data.company?.apiEndpointUsageLogs ?? []).filter(notEmpty),
                      shipper_tours_count: data.company?.shipperToursCount ?? 0,
                      carrier_tours_count: data.company?.carrierToursCount ?? 0,
                    },
                    company_id,
                    company_name: data.company?.company_name ?? null,
                  },
                })}>
                {({ data }) => (
                  <>
                    <IntegrationList
                      integrations={data.integrations}
                      deleteTelematicsIntegration={deleteTelematicsIntegration}
                    />
                    <div style={{ marginTop: '30px' }}>
                      <h2 style={{ marginBottom: '20px' }}>Visibility API</h2>
                      <ApiEndpointUsageLogsList
                        data={[
                          {
                            company_id,
                            company_name: data.company_name,
                            api_endpoint_usage_logs_data: data.api_endpoint_usage_logs_data,
                          },
                        ]}
                        isSingleCompany={true}
                      />
                    </div>
                  </>
                )}
              </LoadingContainer>
            )}
          </DeleteIntegrationMutation>
        )}
      </IntegrationListContainerQuery>
    </React.Fragment>
  );
};
