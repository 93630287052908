import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query } from 'react-apollo';

import { companiesApiUsageLogsQuery } from '../../company/graphql';
import {
  ApiEndpointUsageLog,
  CompaniesApiUsageLogsQuery,
  CompaniesApiUsageLogsQueryVariables,
  getNodes,
} from '../../lib/graphql';
import { ApiEndpointUsageLogsList } from '../components/api_endpoint_usage_log_list';

export type CompanyApiUsageLogs = {
  company_id: string;
  company_name: string | null;
  apiEndpointUsageLogs: ApiEndpointUsageLog[];
};

export interface ListProps {
  companies: {
    company_id: string;
    company_name: string | null;
    api_endpoint_usage_logs: ApiEndpointUsageLog[];
  }[];
}

class LoadingContainer extends Loader<CompaniesApiUsageLogsQuery, ListProps, CompaniesApiUsageLogsQueryVariables> {}
class ApiUsageContainerQuery extends Query<CompaniesApiUsageLogsQuery, CompaniesApiUsageLogsQueryVariables> {}

export const ApiUsageContainer = () => {
  return (
    <React.Fragment>
      <ApiUsageContainerQuery
        query={companiesApiUsageLogsQuery}
        variables={{
          limit: 200,
        }}>
        {(result) => (
          <LoadingContainer
            result={result}
            mapData={(data) => ({
              companies: getNodes(data.data.companies).map((company: CompanyApiUsageLogs) => ({
                company_id: company.company_id,
                company_name: company.company_name,
                api_endpoint_usage_logs: company.apiEndpointUsageLogs,
              })),
            })}>
            {({ companies }) => (
              <>
                <div style={{ marginTop: '30px' }}>
                  <h2 style={{ marginBottom: '20px' }}>Visibility API</h2>
                  <p>
                    This page lists the API usage in Transporeon Visibility. It includes Visibility API as well as other
                    APIs (Sixfold legacy API, OVD).
                  </p>
                  <ApiEndpointUsageLogsList
                    data={companies.map((company) => ({
                      company_id: company.company_id,
                      company_name: company.company_name,
                      api_endpoint_usage_logs_data: {
                        api_endpoint_usage_logs: company.api_endpoint_usage_logs,
                      },
                    }))}
                    isSingleCompany={false}
                  />
                </div>
              </>
            )}
          </LoadingContainer>
        )}
      </ApiUsageContainerQuery>
    </React.Fragment>
  );
};
