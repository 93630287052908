import { gql } from 'graphql-tag';

export const tourQuery = gql`
  query Tour($tour_id: Int!) {
    availableTourTags {
      name
    }

    tour(tour_id: $tour_id) {
      tour_id
      tour_type
      parent_tour_id
      transport_id
      platform_shipper_id
      platform_carrier_id
      shipper_transport_number
      platform_transport_number
      platformBranchId
      start_time
      complete_time
      abort_time
      working_stop_id
      transportMode
      visibilityServices

      isSendEtaToPlatformEnabled
      tourDistance {
        totalDistanceInMeters
      }

      warnings {
        type
        metadata
      }

      failureReason {
        type
      }

      platformCallbacks {
        platform {
          platform_id
          platform_name
        }

        subjectType
        subjectId
        payload
        createdAt
      }

      customFields(includeDeliveries: false) {
        fieldName

        values {
          value
        }
      }

      aborted_by_user {
        user_id
        first_name
        last_name
      }

      site {
        site_id
        name
      }

      created_at
      status
      isTrackingDisabled
      trackingStartTime
      trackingEndTime
      visibility_start_time
      visibility_end_time

      visibilitySegments {
        vehicleId
        visibilityStartDate
        visibilityEndDate
      }

      trackingSegments {
        vehicleId
        trackingStartDate
        trackingEndDate
      }

      tags {
        name
      }

      currentVehicle {
        vehicleId
        vehicleLicensePlateNumber
      }

      activeVehicleAllocation {
        vehicleId
        allocationId
        vehicleLicensePlateNumber
        allocationType
        allocatedAt
        startTrackingAt
        source
        remoteTrackerId
        remoteAllocationSource
        allocationDeviceType
        stopAllocations {
          stopId
          vehicleId
          vehicleLicensePlateNumber
          startTrackingAt
        }
        allocationSwitchType
        disableVehicleMatching
      }

      vehicleAllocations {
        vehicleId
        vehicleLicensePlateNumber
        allocationType
        allocatedAt
        allocatedByCompany {
          company_id
          company_name
        }
        startTrackingAt
        source
        remoteTrackerId
        remoteAllocationSource
        allocationDeviceType
        stopAllocations {
          stopId
          vehicleId
          vehicleLicensePlateNumber
          startTrackingAt
        }
        allocationSwitchType
        disableVehicleMatching
      }

      vehicleAllocationHistory {
        vehicleId
        allocationId
        vehicleLicensePlateNumber
        allocationType
        allocatedAt
        allocatedByCompany {
          company_id
          company_name
        }
        startTrackingAt
        source
        remoteTrackerId
        remoteAllocationSource
        allocationDeviceType
        stopAllocations {
          stopId
          vehicleId
          vehicleLicensePlateNumber
          startTrackingAt
        }
        allocationSwitchType
        disableVehicleMatching
      }

      forwardingChain {
        from {
          company_id
          company_name
        }

        to {
          company_id
          company_name
        }

        forwardedBy {
          user_id
          first_name
          last_name
        }

        forwardedAt
        triggeredByAllocationId
      }

      visibilitySources {
        primaryEventSource
        streamPrecision
        eventOrigin
        sourceCompany {
          company_id
          company_name
        }
        sourcePlatform {
          platform_name
        }
      }

      visibilityIndex

      mainVisibilityIssue {
        type
        index
      }

      visibilityIssues {
        type
        index
      }

      events {
        ... on TourEvent {
          tour_event_name: event_name
          tour_id
          event_id
          vehicle_id
          vehicle_lat
          vehicle_lng
          warning {
            type
          }
          event_index
          event_time
          created_at
          visibilitySource {
            primaryEventSource
            streamPrecision
            eventOrigin
            sourceCompany {
              company_id
              company_name
            }
            sourcePlatform {
              platform_name
            }
          }
          metadata {
            algorithm
            parameters
          }
        }

        ... on StopEvent {
          stop_event_name: event_name
          stop_id
          tour_id
          event_id
          vehicle_id
          vehicle_lat
          vehicle_lng
          event_index
          event_time
          created_at
          visibilitySource {
            primaryEventSource
            streamPrecision
            eventOrigin
            sourceCompany {
              company_id
              company_name
            }
            sourcePlatform {
              platform_name
            }
          }
          metadata {
            algorithm
            parameters
          }
        }
      }

      externalEvents {
        eventId
        eventSource
        qualifier
        sentByCompanyId
        timestamp
        externalEventId
        externalEventSource
        externalEventQualifier
        latitude
        longitude
        licensePlateNumber
        remoteId
        vehicleType
        locationName
        createdAt
      }

      externalStopEvents {
        eventId
        eventSource
        qualifier
        sentByCompanyId
        externalEventId
        externalEventSource
        externalEventQualifier
        stopId
        timestamp
        latitude
        longitude
        createdAt
      }

      externalDeliveryEvents {
        eventId
        deliveryId
        eventSource
        externalEventId
        externalEventSource
        externalEventQualifier
        sentByCompanyId
        qualifier
        timestamp
        latitude
        longitude
        createdAt
      }

      deliveries {
        deliveryId
        deliveryTourId
        deliveryNumber
        purchaseOrderNumber
        orderNumber
        sequenceNumber
        mainMode
        storagePositions

        customFields {
          fieldName
          values {
            value
          }
        }

        transportUnit {
          value
          unit
        }

        volume {
          value
          unit
        }

        loadingLength {
          value
          unit
        }

        incoterms {
          term
          city
        }
      }

      deliveryTree {
        deliveryTreeId
        affectedTourId
        platformDeliveryId
        platformId
        sequenceNumber
        sourceTourId
        parentDeliveryTreeId
        rootDeliveryTreeId
        rootPlatformDeliveryId
        platformPreviousDeliveryId
        deliveryNumber
        purchaseOrderNumber
        orderNumber
        platformSequenceNumber

        loadingStop {
          stopId
        }

        unloadingStop {
          stopId
        }

        customFields
      }

      tour_area {
        ... on GeofencePolygon {
          type
          points {
            lat
            lng
          }
        }
        ... on GeofenceCircle {
          type
          radiusInMeters
          origin {
            lat
            lng
          }
        }
      }

      stops {
        stop_id
        sequence_number
        actual_sequence_number
        platformSequenceNumber
        type
        delivery_ids
        booking_id
        stop_reference_id
        place_reference_id
        expected_duration
        status
        deadline
        etaType
        externalEtaSource
        estimated_arrival
        estimated_arrival_regulated
        estimated_arrival_unregulated
        estimated_arrival_status
        estimated_arrival_accuracy
        estimated_arrival_distance_in_meters
        departure_time
        arrival_time
        arrival_delay_in_seconds

        etas {
          stop_id
          etaType
          externalEtaSource
          estimated_arrival
          estimated_arrival_regulated
          estimated_arrival_unregulated
          estimated_arrival_status
          estimated_arrival_accuracy
          estimated_arrival_distance_in_meters
        }

        vehicleAllocation {
          vehicleId
          vehicleLicensePlateNumber
          startTrackingAt
        }

        timeslot {
          begin
          end
          timezone
        }

        location {
          location_reference_id
          gate_id
          gate
          name
          bookingLocationName

          address {
            full_address
            street_address
            city
            state
            country
            zipcode
            shortAreaCode
          }
          customerProvidedAddress {
            full_address
            street_address
            city
            state
            country
            zipcode
          }

          position {
            lat
            lng
            provider
            geocoding_type
            matchLevel
            geocodingDirection
          }

          place {
            place_id
            name
            isSixfoldGenerated
          }
        }

        geofenceZones {
          zone
          geofence {
            ... on GeofencePolygon {
              type
              points {
                lat
                lng
              }
            }
            ... on GeofenceCircle {
              type
              radiusInMeters
              origin {
                lat
                lng
              }
            }
          }
        }

        status_events {
          stop_id
          event_id
          event_name
          event_index
          event_time
          vehicle_lat
          vehicle_lng
          created_at
          visibilitySource {
            primaryEventSource
            streamPrecision
            eventOrigin
            sourceCompany {
              company_id
              company_name
            }
            sourcePlatform {
              platform_name
            }
          }
        }
      }

      platform {
        platform_name
        platform_id
      }

      shipper {
        company_id
        company_name
      }

      carrier {
        company_id
        company_name
      }

      createdBy {
        company_id
        company_name
      }

      vehicles {
        vehicle_id
        license_plate_number
      }

      status_events {
        event_id
        event_name
        event_index
        event_time
        vehicle_lat
        vehicle_lng
        warning {
          type
        }
        created_at
        visibilitySource {
          primaryEventSource
          streamPrecision
          eventOrigin
          sourceCompany {
            company_id
            company_name
          }
          sourcePlatform {
            platform_name
          }
        }
      }

      route {
        provider

        legs {
          polyline {
            points
          }
        }
      }

      userGroupsOnPlatform {
        groupId
        name
        company {
          company_id
        }
      }

      plannedEmissions {
        co2TankToWheelTonnes
        co2WellToTankTonnes
        co2WellToWheelTonnes
        routePolyline
        cargo {
          containerEmptyWeight
          tonsPerTeu
          tonsPerFeu
          weight
          volumeWeight
          unit
        }
        legs {
          transportMode
          distanceKM
          routePolyline
          type
          startLocation {
            lat
            lng
          }
          endLocation {
            lat
            lng
          }
          emissions {
            type
            emissionMode
            unit
            tankToWheel
            wellToTank
            total
          }
          externalCosts {
            currency
            totalExternalCosts
            accidentCosts {
              transportMode
              accidentCostValue
            }
            noiseCosts {
              transportMode
              noiseCostValue
            }
            emissionCosts {
              name
              tankToWheel
              wellToTank
              total
            }
          }
          carriageParameters {
            bioFuelShare
            carriageModeParameters {
              mode
              emissionClass
              emptyRunFactor
              ferryRouting
              fuelType
              loadFactor
              lorryClass
              driveClass
              aircraftType
              seaShipType
            }
          }
        }
      }

      emissionsCalculationErrors {
        type
        code
      }

      drivenDistance {
        source
        distanceInKilometers
      }

      fuelBasedEmissions {
        accumulatedFuelConsumptionLiters
        fuelType
        co2TankToWheelTonnes
        co2WellToTankTonnes
        co2WellToWheelTonnes
      }

      telemetryEmissions {
        co2TankToWheelTonnes
        co2WellToTankTonnes
        co2WellToWheelTonnes
        routePolyline

        cargo {
          containerEmptyWeight
          tonsPerTeu
          tonsPerFeu
          weight
          volumeWeight
          unit
        }

        legs {
          transportMode
          distanceKM
          routePolyline
          type
          startLocation {
            lat
            lng
          }
          endLocation {
            lat
            lng
          }
          emissions {
            type
            emissionMode
            unit
            tankToWheel
            wellToTank
            total
          }
          externalCosts {
            currency
            totalExternalCosts
            accidentCosts {
              transportMode
              accidentCostValue
            }
            noiseCosts {
              transportMode
              noiseCostValue
            }
            emissionCosts {
              name
              tankToWheel
              wellToTank
              total
            }
          }

          carriageParameters {
            bioFuelShare
            carriageModeParameters {
              mode
              emissionClass
              emptyRunFactor
              ferryRouting
              fuelType
              loadFactor
              lorryClass
              driveClass
              aircraftType
              seaShipType
            }
          }
        }
      }

      rawExternalEvents {
        tourExternalTracking {
          tourId
          provider
          createdAt
          updatedAt
          externalId
          status
          statusReason
        }
        tourExternalTrackingLogs {
          content
          tourId
          contentType
          provider
          createdAt
        }
        tourExternalTrackingRequests {
          trackingRequestId
          tourId
          url
          requestPayload
          createdAt
          responsePayload
          responseStatus
        }
        tourDemurrageDetentionLogs {
          content
          tourId
          createdAt
          demurrageDetentionLogId
        }
      }

      volume {
        unit
        value
      }

      externalCarrier {
        companyID
        companyName
      }

      mainMode
      loadType
      incoterm {
        term
        city
      }
      shipmentType
      metadata

      tripSegments {
        id
        previousTripIds
        transportMethod {
          name
          mode
          identifiers {
            type
            value
          }
        }
        locations {
          location {
            id
            name
            type
            country
          }
          milestones {
            name
            qualifier
            deliveryItemIds
            actualTime {
              dateTime
              timezone
            }
            estimatedTime {
              dateTime
              timezone
            }
            scheduledTime {
              dateTime
              timezone
            }
          }
        }
      }

      tripLocations {
        name
        id
        type
        country
      }

      tripDeliveryItems {
        name
        id
        type
      }
    }
  }
`;

export const vehicleHistoryQuery = gql`
  query TourVehicleHistory($tour_id: Int!, $start_time: String!, $end_time: String!, $simplification_tolerance: Float) {
    tour(tour_id: $tour_id) {
      tour_id

      vehicleStatuses {
        vehicle_id
        latitude
        longitude
        heading
        timestamp
      }

      vehicleHistoryList(
        start_time: $start_time
        end_time: $end_time
        simplification_tolerance: $simplification_tolerance
        limit: 100000
      ) {
        vehicle_id
        history {
          lat
          lng
          hdg
          timestamp
          modeChangesLeft
          vehicleType
          source
          type
          received_at
          created_at
          company {
            company_id
            company_name
          }
          vehicleTracker {
            ... on MobileVehicleTracker {
              vehicleTrackerId
              trackerType
              mobileRemoteId: remoteId
              phoneNumber
            }

            ... on FMSVehicleTracker {
              vehicleTrackerId
              trackerType
              fmsRemoteId: remoteId
              telematicsIntegration {
                telematicsIntegrationId
                telematicsProvider {
                  telematicsProviderName
                  telematicsProviderId
                }
              }
            }
          }
        }
      }

      vehicleBreakHistory(start_time: $start_time, end_time: $end_time) {
        vehicle_id
        breaks {
          breakId
          from
          to

          center {
            lat
            lng
          }
        }
      }
    }
  }
`;

export const abortTourMutation = gql`
  mutation AbortTour($tour_id: Int!) {
    abortTour(tour_id: $tour_id) {
      tour_id
      abort_time
    }
  }
`;

export const recalculateTourMutation = gql`
  mutation RecalculateTour($tour_id: Int!) {
    recalculateTour(tour_id: $tour_id) {
      tour_id
      recalculate_time
    }
  }
`;

export const tourListFilterValuesQuery = gql`
  query TourListFilterValues(
    $vehicle_id: String = "UnselectedFilterPlaceholderId"
    $hasVehicleFilter: Boolean!
    $shipperId: String = "UnselectedFilterPlaceholderId"
    $hasShipperFilter: Boolean!
  ) {
    platforms(first: 1000) {
      edges {
        node {
          platform_id
          platform_name
          platform_branches
        }
      }
    }

    company(company_id: $shipperId) @include(if: $hasShipperFilter) {
      company_id

      placesConnection {
        places(first: 1000) {
          edges {
            node {
              place_id
              name
            }
          }
        }
      }
    }

    vehicle(vehicle_id: $vehicle_id) @include(if: $hasVehicleFilter) {
      vehicle_id
      license_plate_number
    }
  }
`;

export const tourListQuery = gql`
  query TourList(
    $first: Int
    $cursor: String
    $status: TourStatusFilter
    $shipper_ids: [String!]
    $carrier_ids: [String!]
    $platform_id: String
    $platform_branch_id: String
    $place_ids: [String!]
    $vehicle_id: String
    $start_time_start: DateTime
    $start_time_end: DateTime
    $sortOrder: TourSortOrder
    $delayStatus: TourDelayStatusFilter
    $textSearch: TourTextSearch
    $transportMode: [TourFilteringTransportMode!]
  ) {
    tours(
      status: $status
      shipperIds: $shipper_ids
      carrierIds: $carrier_ids
      placeIds: $place_ids
      platformId: $platform_id
      platformBranchId: $platform_branch_id
      vehicleId: $vehicle_id
      startTimeStart: $start_time_start
      startTimeEnd: $start_time_end
      sortOrder: $sortOrder
      delayStatus: $delayStatus
      textSearch: $textSearch
      transportMode: $transportMode
    ) {
      tours(first: $first, after: $cursor) {
        edges {
          node {
            tour_id
            shipper_transport_number
            transport_id
            start_time
            status
            failureReason {
              type
            }
            warnings {
              type
            }
            mainVisibilityIssue {
              type
              index
            }
            visibilityServices
            carrier {
              company_id
              company_name
            }
            shipper {
              company_id
              company_name
            }
            platform {
              platform_id
              platform_name
            }
            vehicles {
              vehicle_id
              license_plate_number
            }
            stops {
              sequence_number
              actual_sequence_number
              display_sequence_number
              location {
                name
                address {
                  country
                }
              }
            }
            tags {
              name
            }
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const tripsDetailsQuery = gql`
  query TripsDetails($tour_id: Int!) {
    tour(tour_id: $tour_id) {
      tour_id
      relatedTransports {
        tour_id
        status
        shipper_transport_number
      }
    }
  }
`;
